<div class="cursor-pointer flex justify-start direction-col g-3" [class.slide-mode]="slideMode" [ngClass]="size">
  <div class="image flex" [class.shorter-img]="!hasDate">
    @if (isDownloadable) {
    <ion-icon class="download" name="download-outline"></ion-icon>
    }
    <img class="h-full w-full" [src]="highlight" onerror="this.src='../../../../assets/images/image-not-found.svg'">
  </div>
  <div class="flex justify-between pe-3">
    <div class="info">
      @if (size === 'xlg' && isRoute('/home')) {
      <p class="know-more pt-3">{{ 'know.more' | translate }}</p>
      }
      <p class="title" [class.longer-title]="!hasDate">{{ item.title }}</p>
      @if (hasDate) {
      <p class="date">{{ item.publishStart | localizedDate }}</p>
      }
    </div>
    @if (urlService.urlContains('/favorites')) {
    @if (unfavoriting) {
    <ion-spinner name="circles"></ion-spinner>
    } @else {
    <ion-icon class="star" name="star" (click)="unfavorite($event)"></ion-icon>
    }
    }
  </div>
</div>
