import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber, catchError, map, of, tap } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { CacheService } from '../../../cache/cache.service';
import { ApiResponse } from '../../../../models/api-response.model';
import { UpdateResponse } from '../../../../models/ws-user/update-password-response.model';
import { Me, MeUpdatePassword } from '../../../../models/ws-user';

const URL_ME = `${environment.url}/me`;

@Injectable({
  providedIn: 'root'
})
export class MeService {
  meSubject$: ReplaySubject<ApiResponse<Me>> = new ReplaySubject<ApiResponse<Me>>(1);

  updated!: Subscriber<boolean>;
  updateState: Observable<boolean> = new Observable((observer) => {
    this.updated = observer;
    this.updated.next(true);
  });
  //https://stackoverflow.com/questions/55339248/how-to-notify-another-component-if-profile-pic-is-updated-so-that-the-changes-ge

  constructor(private http: HttpClient, private cache: CacheService) { }

  getMeFromApi(): Observable<any> {
    return this.http.get<ApiResponse<Me>>(URL_ME, { withCredentials: true }).pipe(
      tap((r) => this.meSubject$.next(r)),
      catchError(() => of(null)));
  }

  updateMe(user: Me) {
    this.cache.clearByKey(URL_ME);
    return this.http.post<ApiResponse<UpdateResponse>>(`${URL_ME}/update`, user, { withCredentials: true });
  }

  updatePassword(passwords: MeUpdatePassword) {
    return this.http.post<ApiResponse<UpdateResponse>>(`${URL_ME}/update/password`, passwords, { withCredentials: true });
  }

  updateInterestArea(idPerson: string, interestAreas: string[]) {
    this.cache.clearByKey(URL_ME);
    return this.http.post<ApiResponse<UpdateResponse>>(`${URL_ME}/update/interest-area`, { idPerson: idPerson, interestAreas: interestAreas }, { withCredentials: true });
  }

  deleteMe() {
    this.cache.clearByKey(URL_ME);
    return this.http.delete<ApiResponse<string>>(`${URL_ME}/`, { withCredentials: true });
  }

  getUserLanguage(): Observable<string> {
    return this.getMeFromApi().pipe(
      map(user => user.languageCode || navigator.language || (navigator as any).userLanguage)
    );
  }
}
