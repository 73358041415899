import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber, catchError, map, of, shareReplay, tap } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { CacheService } from '../../../cache/cache.service';

const URL_ME = `${environment.url}/me`;

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private meCache$?: Observable<any>;
  meSubject$: ReplaySubject<any> = new ReplaySubject<any>(1);

  updated!: Subscriber<boolean>;
  updateState: Observable<boolean> = new Observable((observer) => {
    this.updated = observer;
    this.updated.next(true);
  });
  //https://stackoverflow.com/questions/55339248/how-to-notify-another-component-if-profile-pic-is-updated-so-that-the-changes-ge

  constructor(private http: HttpClient, private cache: CacheService) { }

  getMeCache(): Observable<any> {
    if (!this.meCache$) {
      this.meCache$ = this.getMeFromApi().pipe(
        tap(data => {
          this.meSubject$.next(data); // Update the ReplaySubject with new data
        }),
        shareReplay(1)
      );
    }
    return this.meCache$;
  }
  updateCache(newData: any) {
    this.meSubject$.next(newData);
  }

  getMeFromApi(): Observable<any> {
    return this.http.get<any>(URL_ME, { withCredentials: true }).pipe(
      tap((r) => this.meSubject$.next(r)),
      catchError(() => of(null)));
  }

  updateMe(user: any) {
    this.cache.clearByKey(URL_ME);
    return this.http.post<any>(`${URL_ME}/update`, user, { withCredentials: true });
  }

  updatePassword(passwords: any) {
    return this.http.post<any>(`${URL_ME}/update/password`, passwords, { withCredentials: true });
  }

  updateInterestArea(idPerson: string, interestAreas: string[]) {
    this.cache.clearByKey(URL_ME);
    return this.http.post<any>(`${URL_ME}/update/interest-area`, { idPerson: idPerson, interestAreas: interestAreas }, { withCredentials: true });
  }

  deleteMe() {
    this.cache.clearByKey(URL_ME);
    return this.http.delete<any>(`${URL_ME}/`, { withCredentials: true });
  }

  getUserLanguage(): Observable<string> {
    return this.getMeCache().pipe(
      map(user => user.languageCode || navigator.language || (navigator as any).userLanguage)
    );
  }
}
