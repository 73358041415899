import { Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';

export const routes: Routes = [
  { path: 'home', data: { title: 'page.home' }, component: HomeComponent },
  { path: 'login', data: { title: 'page.login' }, loadChildren: () => import('./pages/login/login.routes').then(r => r.LOGIN_ROUTES) },
  { path: 'procedures', data: { title: 'page.procedures' }, loadChildren: () => import('./pages/procedures/procedures.routes').then(r => r.PROCEDURES_ROUTES) },
  { path: 'news', data: { title: 'page.news' }, loadChildren: () => import('./pages/news/news.routes').then(r => r.NEWS_ROUTES) },
  { path: 'favorites', data: { title: 'page.favorites' }, loadChildren: () => import('./pages/favorites/favorites.routes').then(r => r.FAVORITES_ROUTES) },
  { path: 'products', data: { title: 'page.products' }, loadChildren: () => import('./pages/products/products.routes').then(r => r.PRODUCT_ROUTES) },
  { path: 'events', data: { title: 'page.events' }, loadChildren: () => import('./pages/events/events.routes').then(r => r.EVENTS_ROUTES) },
  { path: 'profile', data: { title: 'page.profile' }, loadChildren: () => import('./pages/profile/profile.routes').then(r => r.PROFILE_ROUTES) },
  { path: 'search', data: { title: 'page.search' }, loadChildren: () => import('./pages/search/search.routes').then(r => r.SEARCH_ROUTES) },
  { path: 'corporate', data: { title: 'page.corporate' }, loadChildren: () => import('./pages/company/company.routes').then(r => r.COMPANY_ROUTES) },
  { path: 'instructions', data: { title: 'page.instructions' }, loadChildren: () => import('./pages/guides/guides.routes').then(r => r.GUIDES_ROUTES) },
  { path: 'web-email', data: { title: 'page.web.email' }, loadChildren: () => import('./web-email/email.routes').then(r => r.EMAIL_ROUTES) },
  { path: 'terms-and-privacy', loadChildren: () => import('./pages/terms-and-privacy/terms-and-privacy.routes').then(r => r.TERMS_ROUTES) },
  { path: 'brasil/press/instructions', redirectTo: 'instructions', pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];
