import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { close, checkmarkOutline, alert } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastCtrl: ToastController, private translate: TranslateService) {
    addIcons({ close, alert, checkmarkOutline });
  }

  icon!: string;
  header!: string;

  displayToast(message: string, cssClass: string) {
    switch (cssClass) {
      case 'error':
        this.header = this.translate.instant('error');
        this.icon = 'alert';
        break;
      case 'success':
        this.header = this.translate.instant('success');
        this.icon = 'checkmark-outline';
        break;
      default:
        break;
    }
    this.toastCtrl.create({
      position: 'bottom',
      header: this.header,
      message: message,
      duration: 5000,
      cssClass: cssClass,
      icon: this.icon,
      buttons: [
        {
          icon: 'close'
        },
      ],
    }).then((toast) => {
      toast.present();
    });
  }

}

