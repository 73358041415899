import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonIcon, IonSpinner } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { star, downloadOutline } from 'ionicons/icons';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { UrlService } from '../../services/url/url.service';
import { LocalizedDatePipe } from '../../utils/pipes/localized-date/localized-date.pipe';

@Component({
  selector: 'app-card-news',
  standalone: true,
  imports: [IonSpinner, CommonModule, IonIcon, LocalizedDatePipe, TranslateModule],
  templateUrl: './card-news.component.html',
  styleUrls: ['./card-news.component.scss'],
})
export class CardNewsComponent implements OnInit {

  @Input() item!: any;
  @Input() isDownloadable = false;
  @Input() slideMode = false;
  @Input() hasDate = true;
  @Output() unfavorited = new EventEmitter<any>();
  highlight!: any;
  unfavoriting = false;
  @Input() size = '';

  constructor(public urlService: UrlService, private router: Router) {
    addIcons({ star, downloadOutline });
  }

  isRoute(route: string): boolean {
    return this.router.url === route;
  }

  ngOnInit() {
    if (this.item.extension) {
      this.highlight = '../../../assets/images/aa.svg';
      return;
    }
    if (Array.isArray(this.item.pictures)) {
      this.highlight = this.item.pictures[0].file
    } else {
      this.highlight = this.item.pictures;
    }
  }

  unfavorite(e: any): void {
    e.stopPropagation();
    this.unfavorited.emit(this.item);
    this.unfavoriting = true;
  }
}
