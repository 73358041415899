import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IonContent, IonInput, IonPopover, IonSearchbar } from "@ionic/angular/standalone";
import { IonIcon } from '@ionic/angular/standalone';
import { apps, caretDownOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';

import { MeService } from '../../services/api/ws-user/me/me.service';
import { ToastService } from '../../services/toast/toast.service';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/api/ws-user/auth/auth.service';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [IonSearchbar, IonInput, RouterLink, IonIcon, TranslateModule, AsyncPipe, JsonPipe, CommonModule, IonPopover, IonContent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit, OnDestroy {

  private loginSubscription!: Subscription;
  user!: any;
  isLoaded = false;
  constructor(private meService: MeService, public themeService: ThemeService, private authService: AuthService, private router: Router) {
    addIcons({ apps, caretDownOutline });
  }

  @ViewChild('popover') popover: any;
  @ViewChild('popoverTrigger') popoverTrigger!: ElementRef;
  isOpen = false;
  @ViewChild('popoverProduct') popoverProduct: any;
  @ViewChild('popoverTriggerProduct') popoverTriggerProduct!: ElementRef;
  isOpenProduct = false;

  presentPopover(e: Event) {
    this.popover.event = {
      target: this.popoverTrigger.nativeElement
    };

    this.isOpen = true;
  }
  presentProductPopover(e: Event) {
    this.popoverProduct.event = {
      target: this.popoverTriggerProduct.nativeElement
    };

    this.isOpenProduct = true;
  }

  ngOnInit(): void {
    if (localStorage.getItem('jwtToken')) {
      this.meService.getMeCache().subscribe();
      this.meService.meSubject$.subscribe({
        next: (r) => {
          this.user = r;
          this.isLoaded = true;
        }
      });
    } else {
      this.isLoaded = true;
      this.loginSubscription = this.authService.getLoginObservable().subscribe({
        next: () => {
          this.meService.meSubject$.subscribe({
            next: (r) => this.user = r
          });
        }
      });
    }
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
  }

  search(e: any) {
    this.router.navigate(['search'], { queryParams: { query: e.target.value } });
  }
}
