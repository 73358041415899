import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withDebugTracing, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import localePtExtra from '@angular/common/locales/extra/pt';
import { NavigationHistoryService } from './services/navigation/navigation-history.service';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeEs, 'es');
registerLocaleData(localePt, 'pt', localePtExtra);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getBrowserLanguage(): string {
    const languageCode = (navigator.language || (navigator as any).userLanguage).slice(0, 2);
    return ['pt-BR', 'en-US', 'es-ES', 'pt', 'en', 'es'].includes(languageCode) ? languageCode : 'en';
  }


export function initializeNavigationHistoryService(navigationHistoryService: NavigationHistoryService) {
  return () => navigationHistoryService.initialize();
}

// const scrollConfig: InMemoryScrollingOptions = {
//   scrollPositionRestoration: 'top',
//   anchorScrolling: 'enabled',
// };

// const inMemoryScrollingFeature: InMemoryScrollingFeature =
//   withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideIonicAngular({}),
    provideHttpClient(withFetch()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeNavigationHistoryService,
      deps: [NavigationHistoryService],
      multi: true,
    },
    importProvidersFrom(CommonModule),
    DatePipe,
    TranslateModule.forRoot({
      defaultLanguage: getBrowserLanguage(),
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }).providers!
  ]
};
