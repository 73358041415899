import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { IonContent, IonPopover, IonSearchbar } from "@ionic/angular/standalone";
import { IonIcon } from '@ionic/angular/standalone';
import { apps, caretDownOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';

import { MeService } from '../../services/api/ws-user/me/me.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/api/ws-user/auth/auth.service';
import { ThemeService } from '../../services/theme/theme.service';
import { Me } from '../../models/ws-user';
import { ApiResponse } from '../../models/api-response.model';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [IonSearchbar, RouterLink, IonIcon, TranslateModule, CommonModule, IonPopover, IonContent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit, OnDestroy {

  private loginSubscription!: Subscription;
  user!: ApiResponse<Me>;
  isLoaded = false;
  constructor(private meService: MeService, public themeService: ThemeService, private authService: AuthService, private router: Router) {
    addIcons({ apps, caretDownOutline });
  }

  @ViewChild('popover') popover!: HTMLIonPopoverElement;
  @ViewChild('popoverTrigger') popoverTrigger!: ElementRef;
  isOpen = false;
  @ViewChild('popoverProduct') popoverProduct!: HTMLIonPopoverElement;
  @ViewChild('popoverTriggerProduct') popoverTriggerProduct!: ElementRef;
  @ViewChild('searchbar') searchbar!: IonSearchbar;
  isOpenProduct = false;

  presentPopover() {
    this.popover.event = {
      target: this.popoverTrigger.nativeElement
    };

    this.isOpen = true;
  }
  presentProductPopover() {
    this.popoverProduct.event = {
      target: this.popoverTriggerProduct.nativeElement
    };

    this.isOpenProduct = true;
  }

  ngOnInit(): void {
    if (localStorage.getItem('jwtToken')) {
      this.meService.getMeFromApi().subscribe();
      this.meService.meSubject$.subscribe({
        next: (r) => {
          this.user = r;
          this.isLoaded = true;
        }
      });
    } else {
      this.isLoaded = true;
      this.loginSubscription = this.authService.getLoginObservable().subscribe({
        next: () => {
          this.meService.meSubject$.subscribe({
            next: (r) => this.user = r
          });
        }
      });
    }
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
  }

  search(e: Event) {
    const target = e.target as HTMLIonSearchbarElement;
    this.router.navigate(['search'], { queryParams: { query: target.value } });
    this.searchbar.value = null;
  }
}
