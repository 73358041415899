import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ApiResponse } from '../../../../models/api-response.model';
import { Country } from '../../../../models/ws-common/country.model';
import { City } from '../../../../models/ws-common/city.model';

const URL = `${environment.common}/location`;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private countryCache$?: Observable<ApiResponse<Country[]>>;

  constructor(private http: HttpClient) { }

  getCountries(): Observable<ApiResponse<Country[]>> {
    if (!this.countryCache$) {
      this.countryCache$ = this.requestCountries().pipe(
        shareReplay(1)
      );
    }
    return this.countryCache$;
  }

  private requestCountries(): Observable<ApiResponse<Country[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With'
    })

    return this.http.get<ApiResponse<Country[]>>(`${URL}/country?PageSize=0`, { headers });
  }

  getCities(countryId: string) {
    return this.http.get<ApiResponse<City[]>>(`${URL}/country/${countryId}/location`);
  }

  getInfoByCityId(cityId: string) {
    return this.http.get<ApiResponse<City>>(`${URL}/location/${cityId}`);
  }
}
