import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../services/api/ws-user/auth/auth.service';
import { TokenStorageService } from '../../services/token/token-storage.service';
import { MeService } from '../../services/api/ws-user/me/me.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private token: string | undefined;
  private lang: string | undefined;
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private authService: AuthService, private tokenService: TokenStorageService, private route: Router, private meService: MeService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): any {
    this.lang = localStorage.getItem('lang') ?? 'pt-BR';
    this.token = this.tokenService.getJwt()!;

    if (this.pathExcludes(request)) {
      request = this.addHeader(request, this.lang, null);
      return next.handle(request);
    }

    if (!this.pathIncludesSpecifics(request)) {
      request = this.addHeader(request, this.lang, null);
      return next.handle(request);
    }

    request = this.addHeader(request, this.lang, this.token);

    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error.status === 401 && error.error.errors[0] === "TokenExpired") {
          return this.handleTokenRefresh(next, request);
        } else if (error.status === 403) {
          this.route.navigate(['/login']);
          return throwError(() => new Error(error.error.errors[0] || "Forbiden"));
        } else {
          if (request.url.includes('favorite')) {
            this.route.navigate(['/login']);
          }

          const errorMsg = error.error.errors[0] || "Unknown Error Occurred";
          return throwError(() => new Error(errorMsg));
        }
      })
    );
  }

  private pathIncludesSpecifics(request: HttpRequest<unknown>): boolean {
    const specificPathsWithId: RegExp[] = [
      /\/products\/api\/v1\/product\/[a-f0-9-]+$/,
      /\/eventhubs\/api\/v1\/eventhub\/[a-f0-9-]+$/,
      /\/contents\/api\/v1\/content\/[a-f0-9-]+$/,
      /\/users\/api\/v1\/me$/,
      /\/users\/api\/v1\/me/,
      /\/users\/api\/v1\/me\/.+$/,
      /\/favorite/,
      /\/users\/api\/v1\/auth\/revoke-token$/,
      /\/products\/api\/v1\/product\/interested$/,
      /\/products\/api\/v1\/product\/training$/
    ];

    return specificPathsWithId.some((pattern) => pattern.test(request.url));
  }
  private pathExcludes(request: HttpRequest<unknown>) {
    const pathEndsExcludes: string[] = [
      '/auth',
      '/auth/verify-email',
      '/auth/forgot-password',
      '/auth/check-password-recovery',
      '/auth/refresh-token',
      '/auth/password-recovery'
    ];

    const pathIncludesExcludes: string[] = [
      '/assets/i18n',
    ];

    return pathEndsExcludes.some(path => request.url.endsWith(path)) ||
      pathIncludesExcludes.some(path => request.url.includes(path));
  }

  private addHeader(request: HttpRequest<unknown>, lang: string, jwt: string | null) {
    const headers: { [key: string]: string } = {
      'Accept-Language': lang,
      'Content-Type': 'application/json',
      "X-Frame-Options": "deny"
    };

    if (jwt) {
      headers['Authorization'] = `Bearer ${jwt}`;
    }

    return request.clone({
      setHeaders: headers
    });
  }

  private handleTokenRefresh(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    this.tokenService.removeJwt();
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next('');

      return this.authService.refreshToken(this.tokenService.getRefresh()).pipe(
        switchMap((newToken: any) => {
          this.tokenService.removeJwt();

          if (newToken.data?.jwtToken) {
            this.tokenService.setJwt(newToken.data.jwtToken);
            this.tokenService.setRefresh(newToken.data.refreshToken);
            this.isRefreshing = false;
            this.refreshTokenSubject.next(newToken.data.jwtToken);
            return next.handle(this.addHeader(request, this.lang || 'pt-BR', newToken.data.jwtToken));
          }
          this.isRefreshing = false;
          this.refreshTokenSubject.next('');
          this.route.navigate(['/login']);
          return throwError(() => new Error('No JWT in refresh token response, redirecting to login'));
        }),
        catchError(() => {
          this.isRefreshing = false;
          this.tokenService.removeJwt();
          // this.tokenService.removeRefresh();
          this.meService.updateCache(null);
          return next.handle(this.addHeader(request, this.lang || 'pt-BR', null));
        })
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter((token: string) => token != ''),
        take(1),
        switchMap((jwt: string) => {
          this.tokenService.setJwt(jwt);
          return next.handle(this.addHeader(request, this.lang || 'pt-BR', jwt));
        })
      );
    }
  }
}
