import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../../environments/environment';
import { Content } from '../../../../models/Content.model';
import { ApiResponse } from '../../../../models/api-response.model';
import { FavoriteResponse, FavoriteResponseList } from '../../../../models/favorite.model';

const URL_CONTENT = `${environment.content}/content`;
const URL_FAVORITES = `${environment.content}/favorite`;


@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient) { }

  getContent(): Observable<ApiResponse<Content[]>> {
    return this.http.get<ApiResponse<Content[]>>(`${URL_CONTENT}/?PageSize=0`, { withCredentials: true });
  }

  getVideos(pageSize: number, pageNumber = 0, categoryIds?: string[]): Observable<ApiResponse<Content[]>> {
    if (categoryIds && categoryIds.length > 0) {
      const str = `Category=${categoryIds.join('&Category=')}`;
      return this.http.get<ApiResponse<Content[]>>(`${URL_CONTENT}/?${str}&TypeContent=0&PageIndex=${pageNumber}&PageSize=${pageSize}`, { withCredentials: true });
    }
    return this.http.get<ApiResponse<Content[]>>(`${URL_CONTENT}/?TypeContent=0&PageIndex=${pageNumber}&PageSize=${pageSize}`, { withCredentials: true });
  }

  getNews(pageSize: number, pageNumber = 0, categoryIds?: string[]): Observable<ApiResponse<Content[]>> {
    if (categoryIds && categoryIds.length > 0) {
      const str = `Category=${categoryIds.join('&Category=')}`;
      return this.http.get<ApiResponse<Content[]>>(`${URL_CONTENT}/?${str}&TypeContent=1&PageIndex=${pageNumber}&PageSize=${pageSize}`, { withCredentials: true });
    }
    return this.http.get<ApiResponse<Content[]>>(`${URL_CONTENT}/?TypeContent=1&PageIndex=${pageNumber}&PageSize=${pageSize}`, { withCredentials: true });
  }

  getContentByCategory(id: string): Observable<ApiResponse<Content[]>> {
    return this.http.get<ApiResponse<Content[]>>(`${URL_CONTENT}?Category=${id}`, { withCredentials: true });
  }

  getContentById(id: string): Observable<ApiResponse<Content>> {
    return this.http.get<ApiResponse<Content>>(`${URL_CONTENT}/${id}`, { withCredentials: true });
  }

  addToContentToFavorite(idFavorite: string) {
    return this.http.post<FavoriteResponse>(URL_FAVORITES, { id: idFavorite }, { withCredentials: true });
  }

  getUserFavoritedContents() {
    return this.http.get<FavoriteResponseList>(URL_FAVORITES, { withCredentials: true });
  }

  getFavoritedNews() {
    return this.http.get<FavoriteResponseList>(`${URL_FAVORITES}/news`, { withCredentials: true });
  }

  getFavoritedVideos() {
    return this.http.get<FavoriteResponseList>(`${URL_FAVORITES}/video`, { withCredentials: true });
  }

  deleteFavorited(id: string) {
    return this.http.delete<FavoriteResponse>(`${URL_FAVORITES}/${id}`, { withCredentials: true });
  }
}
