import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { IonButtons, IonContent, IonHeader, IonIcon, IonMenu, IonMenuButton, IonMenuToggle, IonTitle, IonToolbar, IonButton, IonSearchbar } from '@ionic/angular/standalone';
import { filter } from 'rxjs';
import { search, chevronForward, close, arrowBack } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MeService } from './services/api/ws-user/me/me.service';
import { PageTitleService } from './services/page-title/page-title.service';
import { ThemeService } from './services/theme/theme.service';
import { StorageService } from './services/storage/storage.service';
import { LanguageService } from './services/language/language.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [IonButton, RouterOutlet, NavbarComponent, FooterComponent, IonContent, IonHeader, IonButtons, IonToolbar, IonMenuButton, IonTitle, IonMenu, IonIcon, RouterModule, TranslateModule, IonMenuToggle, CommonModule, IonSearchbar],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  user!: any;
  searchQuery: string = '';
  inputVisible: boolean = false;
  theme = 'light';
  @ViewChild('searchbar', { static: false }) searchbar!: IonSearchbar;
  @ViewChild(IonContent) private ionContent!: IonContent;

  hasFooter: boolean = true;
  hasNavbar = true;
  subMenuContent: boolean = false;
  subMenuProduct: boolean = false;

  window!: Element;
  constructor(private router: Router, private meService: MeService, private translate: TranslateService, private storage: StorageService, private pageTitleService: PageTitleService, private themeService: ThemeService, private languageService: LanguageService) {
    addIcons({ search, chevronForward, close, arrowBack });
  }
  ngOnInit(): void {
    this.pageTitleService.init();
    this.scrollToTop();
    this.getMe();
    this.showNavbar();
    this.languageService.setApplicationLanguage();
    this.handleOldUrl();
  }

  private getMe() {
    if (localStorage.getItem('jwtToken')) {
      this.meService.getMeCache().subscribe();
      this.meService.meSubject$.subscribe({
        next: (r) => {
          this.user = r?.data;
          this.themeService.setThemeWindow(this.user?.theme);
          this.storage.setLanguage(this.translate.currentLang);
        }
      });
    } else {
      this.themeService.setThemeWindow();
    }
  }

  redirectTo(path: string): void {
    this.router.navigateByUrl(path);
  }

  handleOldUrl(): void {
    const currentUrl = window.location.pathname;
    if (currentUrl === '/brasil/press/instructions') {
      this.redirectTo('/instructions');
    }
  }

  showNavbar() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/terms-and-privacy/user-terms') {
          this.hasNavbar = false;
        } else {
          this.hasNavbar = true;
        }
      }
    });
  }

  private scrollToTop() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(async () => {
        if (this.router.getCurrentNavigation()?.extras?.state?.['scrollTop'] !== false) {
          await this.ionContent.scrollToTop();
        }
      });
  }

  showSearchInput() {
    this.inputVisible = true;
    setTimeout(() => {
      this.searchbar.setFocus();
    }, 500);
  }

  hideSearchInput() {
    this.inputVisible = false;
    this.searchQuery = '';
    this.searchbar.value = null;
  }

  search(e: any) {
    this.router.navigate(['search'], { queryParams: { query: e.target.value } });
  }

  closeMenu() {
    this.subMenuContent = false;
    this.subMenuProduct = false;
  }

  debounceCloseMenu() {
    setTimeout(() => {
      this.closeMenu();
    }, 400);
  }
}
