import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clear(): void {
    localStorage.clear();
  }

  setLanguage(language: string) {
    localStorage.setItem('lang', language);
  }

  removeLanguage() {
    localStorage.removeItem('lang');
  }

  getLanguage(): string {
    return localStorage.getItem('lang')!;
  }
}
