import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { Event } from '../../../../models/Event.model';
import { ApiResponse } from '../../../../models/api-response.model';

const URL_EVENTS = `${environment.event}/eventhub`;
const URL_FAVORITES = `${environment.event}/favorite`;

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

  getEvents(pageSize: number = 0, pageNumber: number = 0): Observable<ApiResponse<Event[]>> {
    return this.http.get<ApiResponse<Event[]>>(`${URL_EVENTS}?PageIndex=${pageNumber}&PageSize=${pageSize}`, { withCredentials: true });
  }

  getFavoritedEvents() {
    return this.http.get<ApiResponse<Event[]>>(URL_FAVORITES, { withCredentials: true });
  }

  getEventById(id: string) {
    return this.http.get<ApiResponse<Event>>(`${URL_EVENTS}/${id}`, { withCredentials: true });
  }

  getEventRelatedContent(id: string) {
    return this.http.get<ApiResponse<string[]>>(`${URL_EVENTS}/content/${id}`, { withCredentials: true });
  }

  addToEventToFavorite(idFavorite: string) {
    return this.http.post<any>(URL_FAVORITES, { id: idFavorite }, { withCredentials: true });
  }

  deleteFavorited(id: string) {
    return this.http.delete<any>(`${URL_FAVORITES}/${id}`, { withCredentials: true });
  }

}
