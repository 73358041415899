import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryService {
  private history: string[] = [];

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.history.push(event.url);
        if (this.history.length > 199) {
          this.history.shift();
        }
      });
  }

  initialize() {
  }

  clearHistory() {
    this.history = [];
  }

  getHistory(): string[] {
    return this.history;
  }

  async navigateBack(): Promise<void> {
    if (this.history.length >= 1) {
      this.history.pop();
      const previousUrl = this.history.pop()!;
      this.router.navigateByUrl(previousUrl);
    } else {
      this.router.navigate(['/home']);
    }
  }

  hasPreviousUrl(): boolean {
    return this.history.length > 1;
  }
}
