import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { IonButtons, IonContent, IonHeader, IonIcon, IonMenu, IonMenuButton, IonMenuToggle, IonTitle, IonToolbar, IonButton, IonSearchbar } from '@ionic/angular/standalone';
import { filter } from 'rxjs';
import { search, chevronForward, close, arrowBack } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MeService } from './services/api/ws-user/me/me.service';
import { AuthService } from './services/api/ws-user/auth/auth.service';
import { PageTitleService } from './services/page-title/page-title.service';
import { ThemeService } from './services/theme/theme.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [IonButton, RouterOutlet, NavbarComponent, FooterComponent, IonContent, IonHeader, IonButtons, IonToolbar, IonMenuButton, IonTitle, IonMenu, IonIcon, RouterModule, TranslateModule, IonMenuToggle, CommonModule, IonSearchbar],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  user!: any;
  searchQuery: string = '';
  inputVisible: boolean = false;
  theme = 'light';
  @ViewChild('searchbar', { static: false }) searchbar!: IonSearchbar;
  @ViewChild(IonContent) private ionContent!: IonContent;

  hasFooter: boolean = true;
  hasNavbar = true;
  subMenuContent: boolean = false;
  subMenuProduct: boolean = false;

  window!: Element;
  constructor(private router: Router, private meService: MeService, private translate: TranslateService, private renderer: Renderer2, private pageTitleService: PageTitleService, private themeService: ThemeService) {
    addIcons({ search, chevronForward, close, arrowBack });
  }
  ngOnInit(): void {
    this.pageTitleService.init();
    this.scrollToTop();
    this.getMe();
    this.showNavbar();
  }

  //trazer os usuários antigos pro modelo de json novo
  // setNewLanguageCode() {
  //   const newCode = this.user.languageCode.slice(0, 2);
  //   this.user.persons[0].languageCode = newCode;
  //   this.user.persons[0].theme = this.user.theme;
  //   const locIds = this.user.persons[0].locations.map((location: any) => location.locationId);
  //   this.user.persons[0].locations = locIds;
  //   console.log('qqqqqqq', this.user.persons[0]);

  //   // this.meService.updateMe(this.user.persons[0]).subscribe({
  //   //   next: (r) => console.log('aaaaaaaaaaaaaaaaaa', r.data)

  //   // });
  // }
  private getMe() {
    if (localStorage.getItem('jwtToken')) {
      this.meService.getMeCache().subscribe();
      this.meService.meSubject$.subscribe({
        next: (r) => {
          this.user = r?.data;
          this.themeService.setThemeWindow(this.user.theme);
          this.user.languageCode = this.user.languageCode.slice(0, 2);
          this.translate.use(this.user.languageCode);
        }
      });
    } else {
      this.themeService.setThemeWindow();
    }
  }

  showNavbar() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/terms-and-privacy/user-terms') {
          this.hasNavbar = false;
        } else {
          this.hasNavbar = true;
        }
      }
    });
  }

  private scrollToTop() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(async () => {
        if (this.router.getCurrentNavigation()?.extras?.state?.['scrollTop'] !== false) {
          await this.ionContent.scrollToTop();
        }
      });
  }

  showSearchInput() {
    this.inputVisible = true;
    setTimeout(() => {
      this.searchbar.setFocus();
    }, 500);
  }

  hideSearchInput() {
    this.inputVisible = false;
    this.searchQuery = '';
    this.searchbar.value = null;
  }

  search(e: any) {
    this.router.navigate(['search'], { queryParams: { query: e.target.value } });
  }

  closeMenu() {
    this.subMenuContent = false;
    this.subMenuProduct = false;
  }

  debounceCloseMenu() {
    setTimeout(() => {
      this.closeMenu();
    }, 400);
  }
}
