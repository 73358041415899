import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  darkTheme = false;
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.darkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  getTheme(): string | null {
    return localStorage.getItem('mode');
  }

  setThemeWindow(theme?: string) {
    if (theme === 'dark' || theme === 'light') {
      localStorage.setItem('mode', theme);
      this.renderer.setAttribute(document.body, 'data-theme', theme);
      return theme;
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      localStorage.setItem('mode', 'dark');
      this.renderer.setAttribute(document.body, 'data-theme', 'dark');
      return 'dark';
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      localStorage.setItem('mode', 'light');
      this.renderer.setAttribute(document.body, 'data-theme', 'light');
      return 'light';
    }
    return 'system';
  }
}
