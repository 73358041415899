<div>
  <div class="container cursor-pointer flex justify-center items-center cursor-pointer">
    <div class="day flex direction-col items-center justify-center">
      <p class="number">
        {{ (item.eventStart || item.start) | localizedDate: 'd' }}
      </p>
      <p class="month">
        {{ ((item.eventStart || item.start) | localizedDate: 'MMM') | uppercase }}
      </p>
    </div>
    <img class="h-full w-full" [src]="highlight">
  </div>
  <div class="flex justify-between pe-3 cursor-pointer">
    <div>
      <p class="title pt-3">{{ item.title }}</p>
      <p class="type" [ngStyle]="{'color': item.color}">{{ item.type | translate }}</p>
      <p class="date">{{ startDate | localizedDate: 'EEE, H:mm' }}</p>
      <p class="date">{{ item.cityTitle }}, {{ item.stateShort }}</p>
    </div>
    @if (urlService.urlContains('/favorites')) {
    @if (unfavoriting) {
    <ion-spinner name="circles"></ion-spinner>
    } @else {
    <ion-icon class="star pt-6" name="star" (click)="unfavorite($event)"></ion-icon>
    }
    }
  </div>
</div>
