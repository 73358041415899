import { Injectable } from '@angular/core';

const TOKEN_KEY = 'jwtToken';
const REFRESH_KEY = 'refreshToken';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  static refreshToken: string = '';
  static jwtToken: string = '';
  constructor() { }

  signOut(): void {
    localStorage.clear();
  }

  setJwt(jwt: string) {
    localStorage.setItem(TOKEN_KEY, jwt);
  }

  setRefresh(token: string) {
    localStorage.setItem(REFRESH_KEY, token);
  }

  setTokens(tokens: { jwtToken: string, refreshToken: string }): void {
    localStorage.setItem(TOKEN_KEY, tokens.jwtToken);
    localStorage.setItem(REFRESH_KEY, tokens.refreshToken);
  }

  removeJwt() {
    localStorage.removeItem(TOKEN_KEY);
  }

  getJwt(): string {
    return localStorage.getItem(TOKEN_KEY)!;
  }

  getRefresh(): string {
    return localStorage.getItem(REFRESH_KEY)!;
  }

  loggedIn(): boolean {
    if (this.getJwt()) {
      return true;
    }
    return false;
  }
}
