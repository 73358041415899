import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  constructor(
    private titleService: Title,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  init() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
      map(routeData => routeData['title'])
    ).subscribe(titleKey => {
      if (titleKey) {
        this.translate.get(titleKey).subscribe((translatedTitle: string) => {
          this.titleService.setTitle(translatedTitle);
        });
      } else {
        this.titleService.setTitle('Sintegra Surgical');
      }
    });
  }
}
