<section #home class="main flex direction-col items-center">
  <div class="flex g-6">
    <div class="main-presentation flex direction-col items-start justify-center g-7">
      <h2 class="main-title">{{ 'the' | translate }}
        <span class="highlight-color">{{ 'tech' | translate }}</span> {{ 'tech.more' | translate }}
      </h2>
      <p class="main-subtitle">{{ 'tech.about' | translate }}</p>
      <a class="discover flex g-3 items-center" href="https://www.youtube.com/watch?v=h6nAtR44UDg" target="_blank">
        <ion-icon class="icon-24" name="play-circle"></ion-icon>
        {{ 'sintegra.discover' | translate }}
      </a>
    </div>
    <img class="main-img" src="../../../../../assets/images/header-home.png" alt="Smiling Doctor">
  </div>
  <div class="contact-container p-6 flex items-center g-7 w-full justify-between">
    <ul class="flex g-7 justify-between">
      <li class="flex items-center">
        <ion-icon class="icon-24 pe-5" name="call"></ion-icon>
        <div>
          <p>{{ 'phone' | translate }}</p>
          <p>(14) 3405 - 2373</p>
        </div>
      </li>
      <li class="flex items-center">
        <ion-icon class="icon-24 pe-5" name="mail"></ion-icon>
        <div>
          <p>E-mail</p>
          <p>contato&#64;sintegrasurgical.com.br</p>
        </div>
      </li>
      <li class="flex items-center">
        <ion-icon class="icon-24 pe-5" name="location-sharp"></ion-icon>
        <address>
          <p>{{ 'location' | translate }}</p>
          <p>{{ 'sintegra.street.full' | translate }}</p>
        </address>
      </li>
    </ul>
  </div>
  <ion-icon class="more-icon icon-20" name="chevron-down" (click)="scroll(home)"></ion-icon>
</section>
