import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject, tap } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { StringResponse } from '../../../../models/string.model';

const URL = `${environment.url}/auth`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginSubject = new Subject<any>();
  constructor(private http: HttpClient) { }

  login(user: any) {
    return this.http.post(URL, user, { withCredentials: true }).pipe(
      tap(response => {
        this.loginSubject.next(response); // Notify subscribers about the login event
      })
    );
  }
  getLoginObservable() {
    return this.loginSubject.asObservable();
  }

  logout(obj?: { token: string }) {
    return this.http.post(`${URL}/logout`, obj, { withCredentials: true });
  }

  refreshToken(refresh: any): Observable<any> {
    return this.http.post(`${URL}/refresh-token`, { token: refresh }, { withCredentials: true });
  }

  revokeToken() {
    return this.http.post(`${URL}/revoke-token`, {});
  }

  forgotPassword(email: string) {
    localStorage.clear();
    return this.http.post(`${URL}/forgot-password`, email);
  }

  verifyEmail(obj: { token: string }) {
    return this.http.post(`${URL}/verify-email`, obj);
  }

  checkPasswordRecovery(token: string): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${URL}/check-password-recovery`, { token });
  }

  passwordRecovery(token: string, newPassword: string): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${URL}/password-recovery`, { token, newPassword });
  }
}
