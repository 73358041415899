import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { play } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { IonIcon, IonSpinner } from '@ionic/angular/standalone';
import { UrlService } from '../../services/url/url.service';

@Component({
  selector: 'app-card-shorts',
  standalone: true,
  imports: [IonIcon, IonSpinner],
  templateUrl: './card-shorts.component.html',
  styleUrls: ['./card-shorts.component.scss'],
})
export class CardShortsComponent implements OnInit {

  @Input() item!: any;
  @Output() unfavorited = new EventEmitter<any>();
  unfavoriting = false;
  highlight!: any;
  constructor(public urlService: UrlService) {
    addIcons({ play });
  }

  ngOnInit() {
    if (Array.isArray(this.item.pictures)) {
      const highlightedPicture = this.item.pictures.find((picture: any) => picture.highlight === true);
      if (highlightedPicture) {
        this.highlight = highlightedPicture.file;
      } else {
        this.highlight = this.item.pictures[0].file;
      }
    } else if (typeof this.item.pictures === 'string') {
      this.highlight = this.item.pictures;
    }
  }

  unfavorite(e: any): void {
    e.stopPropagation();
    this.unfavorited.emit(this.item);
    this.unfavoriting = true;
  }
}
