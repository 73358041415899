<footer class="py-9">
  <div class="footer-container flex justify-between pb-7">
    <section>
      <ul>
        <h5 class="pb-4">{{ ('contact' | translate) | uppercase }}</h5>
        <li>
          <address class="pb-7 flex g-4 direction-col">
            <p class="address-title">{{ 'headquarters' | translate }}</p>
            <p>{{ 'sintegra.street' | translate }}</p>
            <p>Pirajá</p>
            <p>17.580-419</p>
            <p>{{ 'sintegra.country' | translate }}</p>
          </address>
        </li>
        <li>
          <address class="flex g-4 direction-col">
            <p class="address-title">{{ 'branch' | translate }}</p>
            <p>Av. Brigadeiro Luis Antônio, 2503</p>
            <p>Jardim Paulista</p>
            <p>01.402-000</p>
            <p>{{ 'sintegra.country' | translate }}</p>
          </address>
        </li>
      </ul>
    </section>
    <section>
      <div class="flex g-4 direction-col">
        <h5>SÍNTEGRA SURGICAL</h5>
        <a [routerLink]="'/products'">{{ 'products' | translate }}</a>
        <a [routerLink]="'/news'">{{ 'updates' | translate }}</a>
        <a [routerLink]="'/events'">{{ 'events' | translate }}</a>
        <a [routerLink]="'/corporate'">{{ 'corporate' | translate }}</a>
      </div>
    </section>
    <section class="flex g-4 direction-col">
      <h5>{{ ('terms.user' | translate) | uppercase }}</h5>
      <a [routerLink]="'terms-and-privacy/privacy-policy'">{{ 'privacy.policy' |
        translate
        }}</a>
      <a [routerLink]="'terms-and-privacy/cookies-policy'">{{ 'cookies.policy' |
        translate }}</a>
      <a [routerLink]="'terms-and-privacy/legal-warning'">{{ 'legal.warning' | translate
        }}</a>
      <a [routerLink]="'terms-and-privacy/user-terms'" target="_blank">{{ 'terms.user' | translate
        }}</a>
    </section>
    <section class="flex direction-col">
      <img class="jacto-logo" src="../../../assets/images/logo-jactogroup-with-icon.svg" alt="Jacto Group Logo">
      <a href="https://www.grupojacto.com.br/quem-somos" target="_blank" class="pb-4">{{ 'about.jacto' | translate
        }}</a>
      <a href="https://jacto.csod.com/ux/ats/careersite/1/home?c=jacto#/" target="_blank">{{ 'work.us' | translate
        }}</a>
    </section>
  </div>

  <div class="footer-container mobile flex wrap items-center justify-between">
      <div class="flex mobile direction-col g-3">
        <h5 class="not">{{ 'follow' | translate }}:</h5>
        <div class="flex mobile items-center justify-between">
          <a class="flex" href="https://www.instagram.com/sintegrasurgical/" target="_blank">
            <img class="icon-24" src="../../../assets/images/icons/insta.svg" alt="Instagram icon">
          </a>
          <a class="flex" href="https://www.facebook.com/sintegrasurgical/" target="_blank">
            <img class="icon-24" src="../../../assets/images/icons/facebook.svg" alt="Facebook icon">
          </a>
          <a class="flex" href="https://www.linkedin.com/company/sintegra-surgical-sciencies/" target="_blank">
            <img class="icon-24" src="../../../assets/images/icons/linkedin.svg" alt="Linkedin icon">
          </a>
          <a class="flex" href="https://www.youtube.com/@sintegrasurgical" target="_blank">
            <img class="icon-24" src="../../../assets/images/icons/youtube.svg" alt="Youtube icon">
          </a>
        </div>
      </div>
      <div class="mobile">
        <img class="sintegra" src="../../../assets/images/logo-with-name-dark.svg" alt="Sintegra logo">
        <p class="pt-4 not">Copyright © 2024 Síntegra. All rights reserved.</p>
      </div>
  </div>
</footer>
